import React, { memo, useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { intlShape } from 'react-intl';

// MUI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Badge from '@material-ui/core/Badge';
import Popper from '@material-ui/core/Popper';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// Core
import Logo from 'assets/images/logo.svg';
import ToggleLocale from 'containers/ToggleLocale';
import forNotif from 'assets/images/for_notif.svg';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Search from 'containers/Search';
import Filter from 'containers/Filter';
import ElevationScroll from './ElevationScroll';

import { useStyles } from './styles';
import { UserContext } from '../../../providers/UserProvider';
import messagesForNotifys from '../../../messages/Profile';
import bell from '../../../assets/images/bell.svg';
import { appActions } from '../../../providers/AppProvider/data';
import { profileSelectors } from '../../../states/Profile';
import HeaderSelect from '../../GrowMenu';
import { getTokenFromCookies } from 'utils/cookies';
import { paths } from 'common/constants';
import { useOutsideClick } from 'hooks/useOutSideClick';

const settigsMenuID = 'settings-menu';
const userMenuID = 'user-menu';

function Desktop(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { intl, messages, openAuthForm, user, logout, newMsgsAmount } = props;

  const { notifys, getNotifysCount, getNotifys } = useContext(UserContext);
  const business = useSelector(profileSelectors.businessProfile);
  const accountStoredTab = sessionStorage.getItem('tab') || 'products';

  const appBarRef = useRef();

  const newNotifysAmount = () => {
    let total = 0;
    for (let i = 0; i < notifys.results.length; i++) {
      if (!notifys.results[i].is_read) {
        total += 1;
      }
    }
    return total;
  };

  const StyledBadge = withStyles(() => ({
    badge: {
      boxShadow: '0 0 0 2px #FC5C65 ',
    },
  }))(Badge);

  const [anchorSettings, setAnchorSettings] = useState(null);
  const [anchorUser, setAnchorUser] = useState(null);
  const [helpMenuOpen, setHelpMenuOpen] = useState(false);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const isSettingsMenuOpen = Boolean(anchorSettings);
  const isUserMenuOpen = Boolean(anchorUser);
  const anchorElNotifRef = useRef(null);
  const [openNotif, setOpenNotif] = useState(false);

  const [scroller, setScroller] = useState(undefined);
  const scrolled = useRef(false);

  useEffect(() => {
    const target = document.getElementById('scroller');

    if (target) {
      setScroller(target);
    }
  }, []);

  const handleOpenNotification = () => {
    setOpenNotif(true);
  };
  const handleCloseNotification = () => {
    setOpenNotif(false);
  };

  useOutsideClick(anchorElNotifRef, handleCloseNotification, openNotif);

  useEffect(() => {
    function handleMouseMove(e) {
      const AppBarRect = appBarRef.current?.getBoundingClientRect();
      if (!AppBarRect || !scroller) return;

      if (scroller.scrollTop < 200) {
        return;
      }

      const y = e.clientY;
      y - AppBarRect.bottom <= 330 ? setShowSearch(true) : setShowSearch(false);
    }
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [scroller]);

  useEffect(() => {
    if (!scroller) return;

    function handleScroll() {
      const AppBarRect = appBarRef.current?.getBoundingClientRect();

      if (!AppBarRect) return;
      const pageY = scroller.scrollTop;

      if (pageY === 0) {
        setShowSearch(true);
        scrolled.current = false;
      } else {
        !scrolled.current && setShowSearch(false);
        scrolled.current = true;
      }
    }

    scroller.addEventListener('scroll', handleScroll);

    return () => scroller.removeEventListener('scroll', handleScroll);
  }, [scroller]);

  const toggleHelpMenuOpen = () => setHelpMenuOpen((prevState) => !prevState);
  const toggleMainMenuOpen = () => setMainMenuOpen((prevState) => !prevState);

  const handleSettingsMenuClose = (e) => {
    const settingsMenu = document.getElementById('settingsAnimation');
    if (e.target.id !== 'clickableItem') {
      settingsMenu.style.transition = 'all 0.2s';
      settingsMenu.style.opacity = '0';
      settingsMenu.style.transform = 'scale(0.9)';
      setTimeout(() => {
        settingsMenu.style.display = 'none';
        setAnchorSettings(null);
      }, 500);
    }
  };

  const handleUserMenuClose = () => {
    const userMenu = document.getElementById('userMenuAnimation');
    userMenu.style.transition = 'all 0.2s';
    userMenu.style.opacity = '0';
    userMenu.style.transform = 'scale(0.9)';
    setTimeout(() => {
      userMenu.style.display = 'none';
      setAnchorUser(null);
    }, 500);
  };

  const handleUserMenuOpen = (event) => {
    const userMenu = document.getElementById('userMenuAnimation');
    if (anchorUser) {
      setAnchorUser(null);
      return false;
    }
    setAnchorUser(event.currentTarget);
    userMenu.style.transition = 'all 0.2s';
    setTimeout(() => {
      userMenu.style.opacity = '1';
      userMenu.style.transform = 'scale(1)';
    }, 200);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    handleUserMenuClose();
  };

  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    handleUserMenuClose();
    setOpen(false);
    logout();
  };

  const openProduct = async (product) => {
    if (!product?.is_read) {
      await dispatch(appActions.updateNotify(product?.id));
      await getNotifysCount();
      await getNotifys();
    }
    setOpenNotif(false);

    if (product?.action !== 'blocked') {
      window.location.pathname = `/products/${product?.product?.id}`;
    }
  };

  const BlogLink = ({ className }) => {
    const selected = location.pathname === '/blog/main';
    return (
      <MenuItem
        component={NavLink}
        to="/blog/main"
        selected={selected}
        classes={{ selected: classes.activeMenuItem }}
        className={`${classes.navLink} ${className}`}
      >
        {intl.formatMessage({ ...messages.blog })}
      </MenuItem>
    );
  };

  const AboutUsLink = ({ className }) => {
    const selected = location.pathname.includes(paths.aboutUs);
    return (
      <MenuItem
        component={NavLink}
        to="/about"
        selected={selected}
        classes={{ selected: classes.activeMenuItem }}
        className={`${classes.navLink} ${className}`}
      >
        {intl.formatMessage({ ...messages.aboutUs })}
      </MenuItem>
    );
  };

  const SupportServiceLink = ({ className }) => (
    <MenuItem
      component="a"
      target="_blank"
      rel="noopener noreferer"
      href="https://api.whatsapp.com/send/?phone=996707797800"
      disableRipple
      className={`${classes.navLink} ${className}`}
    >
      {intl.formatMessage({ ...messages.supportService })}
    </MenuItem>
  );

  const QALink = ({ className }) => {
    const selected = location.pathname === '/qa';
    return (
      <MenuItem
        component={NavLink}
        to="/qa"
        selected={selected}
        className={`${classes.navLink} ${className}`}
        classes={{ selected: classes.activeMenuItem }}
      >
        {intl.formatMessage({ ...messages.questionsAndAswers })}
      </MenuItem>
    );
  };

  const NotificationButton = ({ label, className }) => (
    <div
      onClick={handleOpenNotification}
      className={`${classes.svg} ${className}`}
    >
      <Badge
        overlap="rectangular"
        classes={{ anchorOriginTopRightRectangular: classes.anchorTopRight }}
        badgeContent={newNotifysAmount()}
        color="secondary"
      >
        <IconButton className={classes.headerIcon}>{label}</IconButton>
      </Badge>
    </div>
  );
  const renderSettigsMenu = (
    <div
      onClick={(event) => handleSettingsMenuClose(event)}
      className={classes.unvisableBlock}
      id="settingsAnimation"
    >
      <Popper
        className={classes.settingsMenu}
        anchorEl={anchorSettings}
        id={settigsMenuID}
        open={isSettingsMenuOpen}
        disablePortal
      >
        <MenuItem
          id="clickableItem"
          className={classes.disableHover}
        >
          <ToggleLocale id="clickableItem" />
        </MenuItem>
        <MenuItem
          component="a"
          hlaunchref="https://api.whatsapp.cuseTheom/send?phone=996707797800"
          target="_blank"
          rel="noopener noreferer"
          onClick={handleSettingsMenuClose}
        >
          {intl.formatMessage({ ...messages.help })}
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/policy"
        >
          {intl.formatMessage({ ...messages.policy })}
        </MenuItem>
      </Popper>
    </div>
  );

  const renderUserMenu = (
    <div
      onClick={handleUserMenuClose}
      className={classes.unvisableBlock}
      style={{
        display: isUserMenuOpen ? 'block' : 'none',
      }}
      id="userMenuAnimation"
    >
      <Popper
        className={classes.userMenu}
        anchorEl={anchorUser}
        id={userMenuID}
        open={isUserMenuOpen}
        disablePortal
      >
        <MenuItem
          id="clickableItem"
          onClick={handleUserMenuClose}
        >
          <NavLink
            id="clickableItem"
            to={`/profile/?tab=${accountStoredTab}`}
          >
            {intl.formatMessage({ ...messages.account })}
          </NavLink>
        </MenuItem>
        <MenuItem
          id="clickableItem"
          onClick={handleUserMenuClose}
        >
          <NavLink
            id="clickableItem"
            to="/settings"
          >
            Настройки
          </NavLink>
        </MenuItem>
        <MenuItem
          id="clickableItem"
          component={NavLink}
          to="/profile/my-products"
          onClick={handleUserMenuClose}
        >
          {intl.formatMessage({ ...messages.myProducts })}
        </MenuItem>
        <MenuItem
          id="clickableItem"
          onClick={handleUserMenuClose}
          component={NavLink}
          to="/favorites"
          className={classes.userMenuItem}
        >
          {intl.formatMessage({ ...messages.favorites })}
        </MenuItem>
        <MenuItem
          id="clickableItem"
          onClick={handleUserMenuClose}
          className={classes.userMenuItem}
        >
          <NotificationButton
            label={intl.formatMessage({ ...messages.notifications })}
            className={classes.inUserMenu}
          />
        </MenuItem>
        <MenuItem
          id="clickableItem"
          component={NavLink}
          to="/profile/my-messages"
          onClick={handleUserMenuClose}
          className={classes.userMenuItem}
        >
          {intl.formatMessage({ ...messages.messages })}
        </MenuItem>
        <MenuItem
          id="clickableItem"
          onClick={handleOpen}
        >
          {intl.formatMessage({ ...messages.logout })}
        </MenuItem>
      </Popper>
      <Modal
        className={classes.exitModal}
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '250px',
              position: 'relative',
            }}
          >
            <Box className={classes.exitWindow}>
              <Box className={classes.exitWindowContent}>
                <Typography component="p">
                  {intl.formatMessage({ ...messages.logoutMessage })}
                </Typography>
                <Box className={classes.exitBtn}>
                  <Button
                    onClick={handleLogout}
                    className={classes.exit}
                  >
                    {intl.formatMessage({ ...messages.logoutAgree })}
                  </Button>
                  <Button onClick={handleClose}>
                    {intl.formatMessage({ ...messages.logoutCancel })}
                  </Button>
                </Box>
              </Box>
              <IconButton
                onClick={handleClose}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );

  const categoryPageRgx = /^\/category\/(\w+-?\w*)+\/?$/;

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          color="inherit"
          position="sticky"
          className={classes.appBar}
          ref={appBarRef}
        >
          <Toolbar className={classes.toolbar}>
            <NavLink
              to="/"
              style={{ maxWidth: 100, maxHeight: 25, marginRight: 15 }}
            >
              <img
                alt="Logo"
                className={classes.logo}
                src={Logo}
              />
            </NavLink>
            <>
              {/* Business Btn */}
              <MenuItem
                component={NavLink}
                to="/business-advantages/"
                className={classes.withoutUser}
                selected={location.pathname === '/business-advantages/'}
                classes={{ selected: classes.activeMenuItem }}
                style={{ marginLeft: 25 }}
              >
                {intl.formatMessage({ ...messages.forBusiness })}
              </MenuItem>
            </>
            <>
              {/* <ForBusinessLink /> */}
              <BlogLink />
              <AboutUsLink />
              <HeaderSelect
                open={helpMenuOpen}
                toggleOpen={toggleHelpMenuOpen}
                endIcon={<ExpandMoreIcon />}
                openEndIcon={<ExpandLessIcon />}
                label={intl.formatMessage({ ...messages.assistance })}
                poppersStyle={{ top: '146%', padding: '5px 0 5px 15px' }}
                className={classes.select}
                style={null}
              >
                <SupportServiceLink />
                <QALink />
              </HeaderSelect>
            </>
            <div className={classes.grow} />

            {user && (
              <>
                <HeaderSelect
                  open={mainMenuOpen}
                  toggleOpen={toggleMainMenuOpen}
                  className={classes.menu}
                  label={<MenuIcon />}
                  poppersStyle={{ top: '131%', right: 10, padding: 5 }}
                >
                  <BlogLink className={classes.inMenu} />
                  <AboutUsLink className={classes.inMenu} />
                  <SupportServiceLink className={classes.inMenu} />
                  <QALink className={classes.inMenu} />
                </HeaderSelect>

                <ToggleLocale
                  id="clickableItem"
                  className={classes.languageSelect}
                  style={{ marginRight: user ? 20 : 0 }}
                />
                <IconButton
                  className={classes.headerIcon}
                  component={NavLink}
                  to="/favorites"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99997 18.8873C9.71525 18.8873 9.44074 18.7842 9.22681 18.5968C8.41886 17.8903 7.6399 17.2264 6.95265 16.6408L6.94914 16.6377C4.93422 14.9207 3.19427 13.4378 1.98364 11.9771C0.630339 10.3441 0 8.79578 0 7.10434C0 5.46097 0.563506 3.94485 1.5866 2.83508C2.62191 1.71219 4.0425 1.09375 5.58714 1.09375C6.74162 1.09375 7.7989 1.45874 8.72953 2.1785C9.19919 2.54181 9.62491 2.98645 9.99997 3.5051C10.3752 2.98645 10.8008 2.54181 11.2706 2.1785C12.2012 1.45874 13.2585 1.09375 14.413 1.09375C15.9574 1.09375 17.3782 1.71219 18.4135 2.83508C19.4366 3.94485 19.9999 5.46097 19.9999 7.10434C19.9999 8.79578 19.3698 10.3441 18.0165 11.9769C16.8058 13.4378 15.066 14.9205 13.0514 16.6374C12.3629 17.224 11.5828 17.8889 10.773 18.5971C10.5592 18.7842 10.2845 18.8873 9.99997 18.8873ZM5.58714 2.26532C4.37362 2.26532 3.25881 2.74963 2.44781 3.62915C1.62475 4.52194 1.17141 5.75607 1.17141 7.10434C1.17141 8.52692 1.70013 9.79919 2.88558 11.2296C4.03136 12.6122 5.73561 14.0645 7.70887 15.7462L7.71253 15.7492C8.40238 16.3371 9.18439 17.0036 9.99829 17.7153C10.8171 17.0023 11.6003 16.3347 12.2915 15.7458C14.2646 14.0642 15.9687 12.6122 17.1145 11.2296C18.2998 9.79919 18.8285 8.52692 18.8285 7.10434C18.8285 5.75607 18.3752 4.52194 17.5521 3.62915C16.7413 2.74963 15.6263 2.26532 14.413 2.26532C13.524 2.26532 12.7078 2.54791 11.9871 3.10516C11.3449 3.60199 10.8975 4.23004 10.6352 4.66949C10.5003 4.89548 10.2629 5.03036 9.99997 5.03036C9.73707 5.03036 9.49964 4.89548 9.36475 4.66949C9.10261 4.23004 8.65522 3.60199 8.01283 3.10516C7.29216 2.54791 6.47597 2.26532 5.58714 2.26532Z"
                      fill="#B5B5B5"
                    />
                  </svg>
                </IconButton>
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  variant="middle"
                />
                {/* Bell Icon */}

                <div
                  ref={anchorElNotifRef}
                  className={classes.headerIconForBabel}
                >
                  <NotificationButton
                    label={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0137 20C8.29034 20 6.88867 18.5983 6.88867 16.875C6.88867 16.53 7.16867 16.25 7.51367 16.25C7.85867 16.25 8.13867 16.53 8.13867 16.875C8.13867 17.9092 8.97958 18.75 10.0137 18.75C11.0479 18.75 11.8887 17.9092 11.8887 16.875C11.8887 16.53 12.1687 16.25 12.5137 16.25C12.8587 16.25 13.1387 16.53 13.1387 16.875C13.1387 18.5983 11.737 20 10.0137 20Z"
                          fill="#B5B5B5"
                        />
                        <path
                          d="M16.888 17.5H3.13797C2.33383 17.5 1.67969 16.8459 1.67969 16.0417C1.67969 15.6149 1.86554 15.2109 2.18964 14.9333C3.45718 13.8625 4.17969 12.3059 4.17969 10.6567V8.33328C4.17969 5.11673 6.79642 2.5 10.013 2.5C13.2297 2.5 15.8464 5.11673 15.8464 8.33328V10.6567C15.8464 12.3059 16.5689 13.8625 17.8281 14.9275C18.1606 15.2109 18.3464 15.6149 18.3464 16.0417C18.3464 16.8459 17.6923 17.5 16.888 17.5ZM10.013 3.75C7.4855 3.75 5.42969 5.80582 5.42969 8.33328V10.6567C5.42969 12.6741 4.54559 14.5792 3.00476 15.8817C2.97546 15.9067 2.92969 15.9584 2.92969 16.0417C2.92969 16.1549 3.02475 16.25 3.13797 16.25H16.888C17.0013 16.25 17.0964 16.1549 17.0964 16.0417C17.0964 15.9584 17.0505 15.9067 17.023 15.8833C15.4805 14.5792 14.5964 12.6741 14.5964 10.6567V8.33328C14.5964 5.80582 12.5406 3.75 10.013 3.75Z"
                          fill="#B5B5B5"
                        />
                        <path
                          d="M10.0137 3.75C9.66867 3.75 9.38867 3.47 9.38867 3.125V0.625C9.38867 0.279999 9.66867 0 10.0137 0C10.3587 0 10.6387 0.279999 10.6387 0.625V3.125C10.6387 3.47 10.3587 3.75 10.0137 3.75Z"
                          fill="#B5B5B"
                        />
                      </svg>
                    }
                  />
                  <div
                    className={
                      openNotif
                        ? clsx(classes.notifBlock, classes.notifActive)
                        : classes.notifBlock
                    }
                  >
                    <div className={classes.notificationAlert}>
                      <div className={classes.title}>
                        <Typography className={classes.title1}>
                          {intl.formatMessage({
                            ...messagesForNotifys.notifications,
                          })}
                        </Typography>
                        <IconButton
                          onClick={() => handleCloseNotification()}
                          className={classes.closeBtnNotification}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                      {notifys.results.length > 0 ? (
                        <div className={classes.notifList}>
                          {Object.keys(notifys.results).map((noti) => (
                            <div
                              key={noti}
                              className={classes.listItem}
                              onClick={() =>
                                openProduct(notifys?.results?.[noti])
                              }
                            >
                              <div className={classes.description}>
                                <div className={classes.titleNotify}>
                                  <img
                                    src={forNotif}
                                    alt=""
                                  />
                                  <Typography>
                                    {notifys.results[noti].action === 'stared'
                                      ? intl.formatMessage({
                                          ...messagesForNotifys.favoriteNotify,
                                        })
                                      : notifys.results[noti].action ===
                                        'activated'
                                      ? intl.formatMessage({
                                          ...messagesForNotifys.activeNotify,
                                        })
                                      : notifys.results[noti].action ===
                                        'blocked'
                                      ? intl.formatMessage({
                                          ...messagesForNotifys.blockedNotify,
                                        })
                                      : notifys.results[noti].action ===
                                        'payment'
                                      ? notifys.results[noti][
                                          `title_${intl.locale}`
                                        ]
                                      : ''}
                                  </Typography>
                                  <svg
                                    width="3"
                                    height="3"
                                    viewBox="0 0 3 3"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 useThe0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z"
                                      fill="#757575"
                                    />
                                  </svg>
                                </div>
                                <Typography className={classes.date}>
                                  {intl.formatDate(
                                    +notifys.results[noti].creation_date * 1000,
                                    {
                                      day: '2-digit',
                                      month: 'numeric',
                                      year: 'numeric',
                                    },
                                  )}
                                </Typography>
                              </div>
                              <div className={classes.moreInfo}>
                                <div>
                                  <Typography className={classes.titleProduct}>
                                    {intl.locale === 'ru'
                                      ? notifys.results[noti]?.title_ru
                                      : notifys.results[noti]?.title_ky}
                                  </Typography>
                                  <Typography className={classes.titleProduct}>
                                    {intl.locale === 'ru'
                                      ? notifys.results[noti]?.description_ru
                                      : notifys.results[noti]?.description_ky}
                                  </Typography>
                                  <Typography
                                    className={classes.messageProduct}
                                  >
                                    {notifys.results[noti].action === 'stared'
                                      ? intl.formatMessage(
                                          {
                                            ...messagesForNotifys.notifySecondaryText,
                                          },
                                          {
                                            name: notifys.results[noti].sender
                                              ?.name,
                                          },
                                        )
                                      : notifys.results[noti].action ===
                                        'activated'
                                      ? intl.formatMessage({
                                          ...messagesForNotifys.productAtivatedText,
                                        })
                                      : notifys.results[noti].action ===
                                        'blocked'
                                      ? intl.formatMessage({
                                          ...messagesForNotifys.productBlockedText,
                                        })
                                      : notifys.results[noti].action ===
                                        'payment'
                                      ? notifys.results[noti][
                                          `description_${intl.locale}`
                                        ]
                                      : ''}
                                  </Typography>
                                </div>
                                <div className={classes.productImage}>
                                  <StyledBadge
                                    overlap="rectangular"
                                    variant="dot"
                                    color="secondary"
                                    invisible={notifys.results[noti].is_read}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <Avatar
                                      variant="rounded"
                                      style={{ width: '70px', height: '70px' }}
                                      alt={
                                        notifys.results[noti]?.product?.title
                                      }
                                      src={
                                        notifys.results[noti]?.product
                                          ?.photos[0] &&
                                        notifys.results[noti]?.product
                                          ?.photos[0].photo
                                      }
                                    />
                                  </StyledBadge>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className={classes.noNotifications}>
                          <img
                            src={bell}
                            alt="bell"
                          />
                          <Typography className={classes.blackText}>
                            {intl.formatMessage({
                              ...messagesForNotifys.noNotifys,
                            })}
                          </Typography>
                          <Typography className={classes.greyText}>
                            {intl.formatMessage({
                              ...messagesForNotifys.noNotifysText,
                            })}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  variant="middle"
                />
                <IconButton
                  className={classes.headerIcon}
                  component={NavLink}
                  to="/profile/my-messages"
                >
                  <Badge
                    overlap="rectangular"
                    badgeContent={newMsgsAmount}
                    color="secondary"
                  >
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.125 0.499756H1.87501C0.841067 0.499756 0 1.34082 0 2.37477V13.6248C0 14.6587 0.841067 15.4998 1.87501 15.4998H18.125C19.1589 15.4998 20 14.6587 20 13.6248V2.37477C20 1.34082 19.1589 0.499756 18.125 0.499756ZM18.125 1.74975C18.2099 1.74975 18.2907 1.76737 18.3645 1.79814L10 9.04774L1.63542 1.79814C1.70927 1.76741 1.79005 1.74975 1.87497 1.74975H18.125ZM18.125 14.2497H1.87501C1.53017 14.2497 1.24999 13.9696 1.24999 13.6247V3.11877L9.59045 10.3472C9.70826 10.4491 9.85413 10.4998 10 10.4998C10.1459 10.4998 10.2917 10.4491 10.4096 10.3472L18.75 3.11877V13.6248C18.75 13.9696 18.4698 14.2497 18.125 14.2497Z"
                        fill="#B5B5B5"
                      />
                    </svg>
                  </Badge>
                </IconButton>

                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  variant="middle"
                />
              </>
            )}
            {user ? (
              // User Avatar
              <ListItem
                id={userMenuID}
                aria-controls={userMenuID}
                onClick={handleUserMenuOpen}
                className={classes.user}
              >
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    src={business?.logo ? business?.logo : user.info.photo}
                    alt={business?.name ? business?.name : user.info.name}
                  />
                </ListItemAvatar>
                <ListItemText
                  className="user-name"
                  primary={business?.name ? business?.name : user.info.name}
                />
                {isUserMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>
            ) : (
              <>
                <ToggleLocale
                  id="clickableItem"
                  className={classes.languageSelect}
                />

                <Divider
                  className={`${classes.divider} ${classes.notHidingDivider}`}
                  orientation="vertical"
                  style={{ marginLeft: '20px' }}
                  variant="middle"
                />
                <Button
                  onClick={() => openAuthForm('login')}
                  className={classes.authButton}
                >
                  {intl.formatMessage({ ...messages.signIn })}
                </Button>
                <Divider
                  className={`${classes.divider} ${classes.notHidingDivider}`}
                  orientation="vertical"
                  variant="middle"
                />
                <Button
                  onClick={() => openAuthForm('register')}
                  className={classes.authButton}
                >
                  {intl.formatMessage({ ...messages.signUp })}
                </Button>
                <Divider
                  className={`${classes.divider} ${classes.lastDivider}`}
                  orientation="vertical"
                  variant="middle"
                />

                <HeaderSelect
                  open={mainMenuOpen}
                  toggleOpen={toggleMainMenuOpen}
                  className={classes.menu}
                  label={<MenuIcon />}
                  poppersStyle={{ top: '131%', right: 10, padding: 5 }}
                >
                  <BlogLink className={classes.inMenu} />
                  <AboutUsLink className={classes.inMenu} />
                  <SupportServiceLink className={classes.inMenu} />
                  <QALink className={classes.inMenu} />
                </HeaderSelect>
              </>
            )}
          </Toolbar>

          {(categoryPageRgx.test(location.pathname) ||
            location.pathname === '/') && (
            <>
              <Search
                isMouseNearOrScrolling={showSearch}
                style={{
                  padding: '0 20px',
                  borderTop: '1px solid #f2f2f2',
                }}
              />

              {categoryPageRgx.test(location.pathname) && <Filter />}
            </>
          )}
        </AppBar>
      </ElevationScroll>
      {renderSettigsMenu}
      {renderUserMenu}
    </>
  );
}

Desktop.propTypes = {
  intl: intlShape,
  messages: PropTypes.object.isRequired,
  openAuthForm: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  logout: PropTypes.func,
  newMsgsAmount: PropTypes.number,
};

export default memo(Desktop);
